.project {
  display: flex;
  margin-right: 1em;
}

.project_image {
  width: 100%;
  height: auto;
  border-radius: 1%;
  filter: blur(2px);  
}

.back {
  position: absolute;
  top: 40%;
  background: none;
  border: none;
  outline: none;
  width: 40px;
  height: 75px;
  line-height: 80px;
  color: white;
  text-align: center;
  margin: 0 1px;
  background: rgba(104, 104, 104, 0.3);
  border-radius: 0 75px 75px 0;
  z-index: 25;
  filter: blur(2px);  
}

.back:hover {
  color:#71acd9;
  background-color: rgba(0,0,0,0.1); 
  cursor: pointer;
}

.forward {
  position: absolute;
  top: 40%;
  right: 0;
  background: none;
  border: none;
  outline: none;
  width: 40px;
  height: 75px;
  line-height: 80px;
  color: white;
  text-align: center;
  margin: 0 1px;
  background: rgba(104, 104, 104, 0.3);
  border-radius: 75px 0 0 75px;
  z-index: 25;
  filter: blur(2px);  
}

.forward:hover{
  color:#71acd9;
  background-color: rgba(0,0,0,0.1); 
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: -1%;
  bottom: 0;
  left: -1%;
  right: 0;
  height: 105%;
  width: 105%;
  transition: .5s ease;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 50;
}

.container {
  position: relative;
  width: 100%;
}

.container:hover {
  cursor: pointer;
}

.container:hover .overlay {
  display: none;
}

.container:hover .project_image {
  filter: none;  
}

.container:hover .back {
  filter: none;  
}

.container:hover .forward {
  filter: none;  
}


.text {
  font-family: 'Pontano Sans', sans-serif;
  color: #43494d;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 1rem;
  background-color: white;
  border: 1px solid rgba(226, 227, 228, 1);
  border-radius: 4px;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@media (max-width: 1014px) {
  .text {
    font-size: 2rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}