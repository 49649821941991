
.modal {
  transition: 1.1s ease-out;
  box-shadow: 
    1rem 1rem 1.5rem rgba(0, 0, 0, 0.3);
  filter: blur(0);
  transform: scale(1);  
  opacity: 1;
  visibility: visible;
  position: absolute;
  z-index: 200;
  position: fixed; 
  top: 15vh; 
  left: 37vw;
  right: 15vw;
  max-width: 60%;
  margin-right: 3vw;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.content {
  background: white;
  /* border: 1px solid #ccc; */
  border-radius: 1%;
  padding: 1.5rem;
}

.content h3 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 2rem;
  color: #71acd9;
}

.content h5 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 1.3rem;
  color: #71acd9;
}

.content p {
  color: #43494d;
}

.content button {
  position: absolute;
  top: 5%;
  right: 1.5rem;
  color: lightblue;
  border: none;
  background: none;
}

.content button:hover {
  color: black
}

.project_stack{ 
  list-style: circle;
  columns: 2;
  padding: 0 0 0 10%;
}

.project_stack li {
  color: #43494d;
}

.project_link {
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
}

.project_link label {
  color: lightblue;
  display: flex;
  align-self: center;
}

.github_icon {
  padding-left: 0.5rem;
  color: lightblue
}

.github_icon:hover{
  color: black;
}

/* .github_icon:hover, .project_link label:hover {
  color: black;
} */


@media (max-width: 860px) {
  .modal {
    left: 20vw
  }
  .project_stack{ 
    columns: 1;
    padding: 0 0 0 15%;
  }
}
