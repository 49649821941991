@import url('https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap');

.about {
  padding-left: 20rem;
  display: flex;
}

.picture {
  position: relative
}

.picture::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20rem;
  border: 2px solid#b8e2fc;
  border-radius: 1%;
  left: 1rem;
  top: 10.5rem;
  z-index: -1;
  animation: fadeIn ease 10s;
}

.picture img {
  height: 20rem;
  width: 20rem;
  border-radius: 1%;
  margin-top: 10rem;

}

.about_me {
  margin-top: 20rem;
  margin-left: 0.5rem;
  margin-right: 5rem;
  border: 2px solid#d7eaf5;
  border-radius: 1%;

  padding: 2rem;
  position: relative;
  animation: fadeIn ease 2s;
}

.about_text {
  animation-duration: 4s;
  animation-name: slideT2B;
}

.about_text h2 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 3rem;
  text-decoration: underline solid lightblue;
}

.about_text h4 {
  font-family: 'Pontano Sans', sans-serif;
}

.about_me::after {
  border-top: 10px solid #f5fcff;
  border-radius: 1%;
  background-color: #f5fcff;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: -1;
  width: 101%;
  height: 100%;
  animation: fadeIn ease 4s;
}

.about_me p {
  color: #607a8a;
}
.about_me h2 {
  color: #43494d;
}

.about_me h4 {
  color: #43494d;
}

.about_me ul {
  columns: 2;
}

.about_me li {
  color: #607a8a;
  position: relative;
  list-style-type: circle;
}

@media (max-width: 860px) {
  .about {
    padding-top: 0;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .picture img {
    margin-top: 8rem;
  }
  .picture::after {
    left: 0.6rem;
    top: 8.5rem;
    width: 20rem;
  }

  .about_me {
    margin-top: 3rem;
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

@media (max-width: 360px) {
  .picture img {
    height: 17rem;
    width: 17rem;
  }
  .picture::after {
    left: 0.6rem;
    top: 8.5rem;
    width: 17rem;
    height: 17rem;
  }
}



@keyframes slideL2R {
  from {
    margin-left: -100%;
    margin-top: -50%
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slideT2B {
  from {
    height: 100%;
    margin-top: -100%
  }

  to {
    height: 100%;
    margin-top: 0rem;
  }
}

.fade-in-about {
  animation: fadeIn ease 5.5s;
  -webkit-animation: fadeIn ease 5.5s;
  -moz-animation: fadeIn ease 5.5s;
  -o-animation: fadeIn ease 5.5s;
  -ms-animation: fadeIn ease 5.5s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}