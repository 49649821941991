.side-bar {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0px 5px rgba(114, 190, 237, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-bar.open {
  transform: translateX(0);
}

.side-bar ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.side-bar li {
  margin: 0.5rem 0;
}

.side-bar a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #2a2c2e
}

.side-bar a:hover,
.side-bar a:active {
  color: #71acd9
}

@media (min-width: 861px) {
  .side-bar {
    display: none;
  }
}