@import url('https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap');

.main {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  justify-content: center;
  left: 30%;
}

.greeting{
  font-size: 1.5rem;
  color: #607a8a;
  font-family: 'Pontano Sans', sans-serif;
}

.full_name {
  font-size: 4rem;
  margin: 0;
  color: #43494d;
  font-family: 'Pontano Sans', sans-serif;
}

.name {
  position: relative;
}

.stroke {
  position: absolute;
  max-width: 100%;
  z-index: -1;
  top: 1.2rem;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 3000px;
}

@media (max-width: 860px) {
  .main {
    left: 10%;
  }
}

@media (max-width: 388px) {
  .stroke {
    width: 10rem;
  }
}


.fade-in-main {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}