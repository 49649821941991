.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 250px;
  background-color: rgba(0,0,0,0.1);
  z-index: 100;
}

@media (max-width: 860px) {
  .backdrop {
    left: 0;
  }
}