@import url(https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap);
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  margin-left: 350px;
}

@media (max-width: 768px) {
  main {
    margin-top: 80px;
    margin-left: 0px;
  }
}
.main {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  justify-content: center;
  left: 30%;
}

.greeting{
  font-size: 1.5rem;
  color: #607a8a;
  font-family: 'Pontano Sans', sans-serif;
}

.full_name {
  font-size: 4rem;
  margin: 0;
  color: #43494d;
  font-family: 'Pontano Sans', sans-serif;
}

.name {
  position: relative;
}

.stroke {
  position: absolute;
  max-width: 100%;
  z-index: -1;
  top: 1.2rem;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 3000px;
}

@media (max-width: 860px) {
  .main {
    left: 10%;
  }
}

@media (max-width: 388px) {
  .stroke {
    width: 10rem;
  }
}


.fade-in-main {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.about {
  padding-left: 20rem;
  display: flex;
}

.picture {
  position: relative
}

.picture::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20rem;
  border: 2px solid#b8e2fc;
  border-radius: 1%;
  left: 1rem;
  top: 10.5rem;
  z-index: -1;
  -webkit-animation: fadeIn ease 10s;
          animation: fadeIn ease 10s;
}

.picture img {
  height: 20rem;
  width: 20rem;
  border-radius: 1%;
  margin-top: 10rem;

}

.about_me {
  margin-top: 20rem;
  margin-left: 0.5rem;
  margin-right: 5rem;
  border: 2px solid#d7eaf5;
  border-radius: 1%;

  padding: 2rem;
  position: relative;
  -webkit-animation: fadeIn ease 2s;
          animation: fadeIn ease 2s;
}

.about_text {
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-name: slideT2B;
          animation-name: slideT2B;
}

.about_text h2 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 3rem;
  -webkit-text-decoration: underline solid lightblue;
          text-decoration: underline solid lightblue;
}

.about_text h4 {
  font-family: 'Pontano Sans', sans-serif;
}

.about_me::after {
  border-top: 10px solid #f5fcff;
  border-radius: 1%;
  background-color: #f5fcff;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: -1;
  width: 101%;
  height: 100%;
  -webkit-animation: fadeIn ease 4s;
          animation: fadeIn ease 4s;
}

.about_me p {
  color: #607a8a;
}
.about_me h2 {
  color: #43494d;
}

.about_me h4 {
  color: #43494d;
}

.about_me ul {
  -webkit-columns: 2;
          columns: 2;
}

.about_me li {
  color: #607a8a;
  position: relative;
  list-style-type: circle;
}

@media (max-width: 860px) {
  .about {
    padding-top: 0;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .picture img {
    margin-top: 8rem;
  }
  .picture::after {
    left: 0.6rem;
    top: 8.5rem;
    width: 20rem;
  }

  .about_me {
    margin-top: 3rem;
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

@media (max-width: 360px) {
  .picture img {
    height: 17rem;
    width: 17rem;
  }
  .picture::after {
    left: 0.6rem;
    top: 8.5rem;
    width: 17rem;
    height: 17rem;
  }
}



@-webkit-keyframes slideL2R {
  from {
    margin-left: -100%;
    margin-top: -50%
  }

  to {
    margin-left: 0%;
  }
}



@keyframes slideL2R {
  from {
    margin-left: -100%;
    margin-top: -50%
  }

  to {
    margin-left: 0%;
  }
}

@-webkit-keyframes slideT2B {
  from {
    height: 100%;
    margin-top: -100%
  }

  to {
    height: 100%;
    margin-top: 0rem;
  }
}

@keyframes slideT2B {
  from {
    height: 100%;
    margin-top: -100%
  }

  to {
    height: 100%;
    margin-top: 0rem;
  }
}

.fade-in-about {
  animation: fadeIn ease 5.5s;
  -webkit-animation: fadeIn ease 5.5s;
  -moz-animation: fadeIn ease 5.5s;
  -o-animation: fadeIn ease 5.5s;
  -ms-animation: fadeIn ease 5.5s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.projects { 
  padding-left: 20rem;
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.projects h1 {
  font-family: 'Pontano Sans', sans-serif;
  color: #43494d;
  font-size: 2.5rem;
  -webkit-text-decoration: underline solid lightblue;
          text-decoration: underline solid lightblue;
}

.row {
  display: flex;
}

.column {
  flex: 50% 1;
  max-height: inherit;
  margin-bottom: calc(3vh + 10px);
  margin-right: 1.5rem;
}

@media (max-width: 860px) {
  .projects {
    padding-left: 1.5rem;
    margin-top: 5rem;
  }
  .row {
    flex-direction: column;
  }
}

.project {
  display: flex;
  margin-right: 1em;
}

.project_image {
  width: 100%;
  height: auto;
  border-radius: 1%;
  -webkit-filter: blur(2px);
          filter: blur(2px);  
}

.back {
  position: absolute;
  top: 40%;
  background: none;
  border: none;
  outline: none;
  width: 40px;
  height: 75px;
  line-height: 80px;
  color: white;
  text-align: center;
  margin: 0 1px;
  background: rgba(104, 104, 104, 0.3);
  border-radius: 0 75px 75px 0;
  z-index: 25;
  -webkit-filter: blur(2px);
          filter: blur(2px);  
}

.back:hover {
  color:#71acd9;
  background-color: rgba(0,0,0,0.1); 
  cursor: pointer;
}

.forward {
  position: absolute;
  top: 40%;
  right: 0;
  background: none;
  border: none;
  outline: none;
  width: 40px;
  height: 75px;
  line-height: 80px;
  color: white;
  text-align: center;
  margin: 0 1px;
  background: rgba(104, 104, 104, 0.3);
  border-radius: 75px 0 0 75px;
  z-index: 25;
  -webkit-filter: blur(2px);
          filter: blur(2px);  
}

.forward:hover{
  color:#71acd9;
  background-color: rgba(0,0,0,0.1); 
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: -1%;
  bottom: 0;
  left: -1%;
  right: 0;
  height: 105%;
  width: 105%;
  transition: .5s ease;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 50;
}

.container {
  position: relative;
  width: 100%;
}

.container:hover {
  cursor: pointer;
}

.container:hover .overlay {
  display: none;
}

.container:hover .project_image {
  -webkit-filter: none;
          filter: none;  
}

.container:hover .back {
  -webkit-filter: none;
          filter: none;  
}

.container:hover .forward {
  -webkit-filter: none;
          filter: none;  
}


.text {
  font-family: 'Pontano Sans', sans-serif;
  color: #43494d;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 1rem;
  background-color: white;
  border: 1px solid rgba(226, 227, 228, 1);
  border-radius: 4px;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@media (max-width: 1014px) {
  .text {
    font-size: 2rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.modal {
  transition: 1.1s ease-out;
  box-shadow: 
    1rem 1rem 1.5rem rgba(0, 0, 0, 0.3);
  -webkit-filter: blur(0);
          filter: blur(0);
  transform: scale(1);  
  opacity: 1;
  visibility: visible;
  position: absolute;
  z-index: 200;
  position: fixed; 
  top: 15vh; 
  left: 37vw;
  right: 15vw;
  max-width: 60%;
  margin-right: 3vw;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.content {
  background: white;
  /* border: 1px solid #ccc; */
  border-radius: 1%;
  padding: 1.5rem;
}

.content h3 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 2rem;
  color: #71acd9;
}

.content h5 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 1.3rem;
  color: #71acd9;
}

.content p {
  color: #43494d;
}

.content button {
  position: absolute;
  top: 5%;
  right: 1.5rem;
  color: lightblue;
  border: none;
  background: none;
}

.content button:hover {
  color: black
}

.project_stack{ 
  list-style: circle;
  -webkit-columns: 2;
          columns: 2;
  padding: 0 0 0 10%;
}

.project_stack li {
  color: #43494d;
}

.project_link {
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
}

.project_link label {
  color: lightblue;
  display: flex;
  align-self: center;
}

.github_icon {
  padding-left: 0.5rem;
  color: lightblue
}

.github_icon:hover{
  color: black;
}

/* .github_icon:hover, .project_link label:hover {
  color: black;
} */


@media (max-width: 860px) {
  .modal {
    left: 20vw
  }
  .project_stack{ 
    -webkit-columns: 1; 
            columns: 1;
    padding: 0 0 0 15%;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 250px;
  background-color: rgba(0,0,0,0.1);
  z-index: 100;
}

@media (max-width: 860px) {
  .backdrop {
    left: 0;
  }
}
.contact{
  padding-left: 20rem;  
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.contact h1 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 3rem;
  color: #43494d;
  margin-top: 50vh;
  -webkit-text-decoration: underline solid lightblue;
          text-decoration: underline solid lightblue;
}

.contact ul {
  list-style: none; 
  margin-top: 39vh;
  margin-left: 10%;
  margin-right: 10%;
  border: 2px solid#d7eaf5;
  border-radius: 1%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3rem;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.contact ul::after {
  border-top: 10px solid #f5fcff;
  border-radius: 1%;
  background-color: #f5fcff;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: -1;
  width: 101%;
  height: 100%;
}

.contact li {
  margin: 1rem;
}

.contact li a {
  text-decoration: none;
  color: #43494d;
}

.icon {
  margin-right: 1rem;
}

.link:hover{
  color: lightblue;
}

@media (max-width: 860px) {
  .contact {
    padding-left: 10%;
    flex-direction: column;
    padding-top: 7rem;
  }

  .contact h1 {
    margin-top: 0;
    font-size: 2.5rem;
  }

  .contact ul {
    margin-left: 0;
    margin-top: 1.5rem;
    padding: 1.5rem;
  }
}
.max {
  padding-left: 20rem;
}

.max h1 {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 3rem;
  color: #43494d;
  -webkit-text-decoration: underline solid lightblue;
          text-decoration: underline solid lightblue;
}
.header{
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color:white;
  overflow-x: hidden;
  /* box-shadow: 1px 0px 5px rgba(115, 190, 237, 0.28); */
  border-right: 1px solid #ebf5fa
}

.header_navigation {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}

.side_nav_items ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.side_nav_items li {
  margin: 0.5rem 0;
}

.side_nav_items a {
  /* text-decoration: none; */
  font-size: 1.2rem;
  /* color: #2a2c2e */
  color: #71acd9;
  /* color: black; */
  -webkit-text-decoration:  underline solid lightgrey;
          text-decoration:  underline solid lightgrey;
}

.side_nav_items a:hover, 
.side_nav_items a:active{
  /* color: #71acd9; */
  color: black;
}

@media (max-width: 860px) {
  .side_nav_items {
    width: 100%
  }
  .side_nav_items ul {
    flex-direction: row;
    padding-left: 0;
    justify-content: space-evenly;
  }
  .header {
    background-color: white;
    height: 75px;
    width: 100%;
    border-bottom: 1px solid #ebf5fa;
  }
  .header_navigation {
    padding: 0.5rem;
    align-items: flex-start;
    height: 60px;
    width: 100%;
  }
  .header_toggle_button {
    display: flex;
    flex-direction: row;
  }
  h5 {
    color: #2a2c2e;
  }
}

@media (min-width: 861px) {
  .header_toggle_button {
    display: none;
  }

}

.profile_pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid white;
}

/* h4 {
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
} */
.toggle-button {
  background: none;
  outline: none;
  border: none;
  color:#2a2c2e;
  top: 0;
}
.side-bar {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0px 5px rgba(114, 190, 237, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-bar.open {
  transform: translateX(0);
}

.side-bar ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.side-bar li {
  margin: 0.5rem 0;
}

.side-bar a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #2a2c2e
}

.side-bar a:hover,
.side-bar a:active {
  color: #71acd9
}

@media (min-width: 861px) {
  .side-bar {
    display: none;
  }
}
