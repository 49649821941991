.projects { 
  padding-left: 20rem;
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.projects h1 {
  font-family: 'Pontano Sans', sans-serif;
  color: #43494d;
  font-size: 2.5rem;
  text-decoration: underline solid lightblue;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  max-height: inherit;
  margin-bottom: calc(3vh + 10px);
  margin-right: 1.5rem;
}

@media (max-width: 860px) {
  .projects {
    padding-left: 1.5rem;
    margin-top: 5rem;
  }
  .row {
    flex-direction: column;
  }
}
