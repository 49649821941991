.header{
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color:white;
  overflow-x: hidden;
  /* box-shadow: 1px 0px 5px rgba(115, 190, 237, 0.28); */
  border-right: 1px solid #ebf5fa
}

.header_navigation {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}

.side_nav_items ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.side_nav_items li {
  margin: 0.5rem 0;
}

.side_nav_items a {
  /* text-decoration: none; */
  font-size: 1.2rem;
  /* color: #2a2c2e */
  color: #71acd9;
  /* color: black; */
  text-decoration:  underline solid lightgrey;
}

.side_nav_items a:hover, 
.side_nav_items a:active{
  /* color: #71acd9; */
  color: black;
}

@media (max-width: 860px) {
  .side_nav_items {
    width: 100%
  }
  .side_nav_items ul {
    flex-direction: row;
    padding-left: 0;
    justify-content: space-evenly;
  }
  .header {
    background-color: white;
    height: 75px;
    width: 100%;
    border-bottom: 1px solid #ebf5fa;
  }
  .header_navigation {
    padding: 0.5rem;
    align-items: flex-start;
    height: 60px;
    width: 100%;
  }
  .header_toggle_button {
    display: flex;
    flex-direction: row;
  }
  h5 {
    color: #2a2c2e;
  }
}

@media (min-width: 861px) {
  .header_toggle_button {
    display: none;
  }

}

.profile_pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid white;
}

/* h4 {
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
} */